<template>
<div>
        <form @submit.prevent="onSubmit" @keydown="keydownform($event)">
        <div class="row">
            <div class="col-md-6">
                <div class="panel panel-cascade">
                    <div class="info-box  bg-purple  text-white">
                        <div class="info-icon bg-warning-dark">
                            <i class="fa fa fa-user fa-2x"></i>
                        </div>
                        <div class="info-details">
                            <h4>Personal Details</h4>
                        </div>
                    </div>
                
                    <div class="panel-body">
                        <div class="form-horizontal cascde-forms">
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Vsdigi Id<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" type="text" v-model="vsdigiid" @input="searchvsdigi()" required>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Select Branch<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <select class="form-control form-cascade-control input-small .dropdown-menu" style="background-color: rgb(255, 255, 255);" v-model="form.branchid" required>
                                        <option v-for="(item) in getmastertype(MASTER_BRANCH_TYPE)" v-bind:key="item.id" :value="item.id">{{ item.label }}</option>
                                    </select>
                                    <font color="red"><span class="field-validation-valid" data-valmsg-for="LST_BRANCH" data-valmsg-replace="true"></span></font>
                                </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Employee Name<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" data-val="true" data-val-required="Required" id="EMPLOYEENAME" name="EMPLOYEENAME" type="text" value="" v-model="form.name" required>
                                    <input type="hidden" class="form-control" v-model="form.isemployee" required>
                                    <font color="red"><span class="field-validation-valid" data-valmsg-for="EMPLOYEENAME" data-valmsg-replace="true"></span></font>
                                </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Mobile No<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                 <div class="col-lg-8 col-md-9">
                                 <mobiles></mobiles>
                                 </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Pay Scale<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">

                                    <input class="form-control form-cascade-control input-small" v-model="form.payscale"  type="number" value="0">

                                    <font color="red"><span class="field-validation-valid" ></span></font>
                                </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Fuel<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.fuel" type="number" value="0" required>
                                </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Deduction Per Day<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.deductionperday" type="number" value="0" required>
                                </div>
                            </div>
                            <div class="form-group row Authority" style="">
                                <label class="col-lg-4 col-md-3 control-label">Photo</label>
                                <div class="col-lg-8 col-md-9">
                                    
                                    <img :src="profileimageurl" width="100px" height="100px"/>
                                    <upload></upload>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div> <!-- / purple Panel -->

            </div>

            <div class="col-md-6 Authority" style="">
                <div class="panel panel-cascade">
                    <div class="info-box  bg-purple  text-white">
                        <div class="info-icon bg-warning-dark">
                           <i class="fa fa-unlock fa-2x"></i>
                        </div>
                        <div class="info-details">
                            <h4>Login Details</h4>
                        </div>
                    </div>
                    <div class="panel-body">
                    
                        <div class="form-horizontal cascde-forms">
                        
                            <address-form></address-form>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Email<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <!-- <div v-if="!newemail">{{ edititem.email }}</div> -->
                                    <input class="form-control form-cascade-control input-small" data-val="true" v-model="form.email" data-val-required="Required"  type="email"  style="background-color: rgb(255, 255, 255);" required>
                                    <div class="col-md-12 form-error"><span class="help text-danger" v-text="form.errors.get('email')"></span></div>

                                    </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">User ID<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <!-- <div v-if="!newusername">{{ edititem.username }}</div> -->
                                    <input  class="form-control form-cascade-control input-small" data-val="true" v-model="form.username" data-val-required="Required"  type="email"  style="background-color: rgb(255, 255, 255);" required>
                                    <div class="col-md-12 form-error"><span class="help text-danger" v-text="form.errors.get('username')"></span></div>

                                    </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Password</label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.password" data-val="true" data-val-required="Required" id="PASSWORD" name="PASSWORD" type="password" required>
                                    <font color="red"><span class="field-validation-valid" data-valmsg-for="PASSWORD" data-valmsg-replace="true"></span></font>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-md-3 control-label">Conform Password<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                <div class="col-lg-8 col-md-9">
                                    <input class="form-control form-cascade-control input-small" v-model="form.confirm_password" data-val="true" data-val-equalto="Confirm password doesn't match, Type again !" data-val-equalto-other="*.PASSWORD" data-val-required="Mandaory Entry" id="CPASSWORD" name="CPASSWORD" type="password" required>
                                    <font color="red"><span class="field-validation-valid" data-valmsg-for="CPASSWORD" data-valmsg-replace="true"></span></font>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> <!-- / purple Panel -->
            </div>
        </div>
        </form>
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="info-box  bg-purple  text-white">
                    <div class="info-icon bg-warning-dark">
                        <i class="fa fa-cogs fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Select User Authority</h4>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="flex-around-row" style="text-align:center;">
                        <button type="button" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" @click="submit()" class="btn bg-primary text-white btn-sm chkcondition">Submit</button>
                        <span v-if="isemp" style="color:green ;">Already an employee</span>
                        <input type="button" value="Cancel" style="background-color:#495b79!important;margin-top:23px;margin-left:35px;" @click="cancel()" class="btn bg-primary text-white btn-sm">
                    </div>
                </div>
            </div>

        </div>
    
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Constants from '../../components/utilities/Constants.vue'
import Mobiles from '../../components/mobile/Mobiles.vue'
import AddressForm from '../../components/address/AddressForm.vue'
import Upload from '../../components/upload/Upload.vue'
export default {
    mixins:[Constants],
    components:{Mobiles,AddressForm,Upload},
	data(){
		return {
            form: new window.Form({
                id:0,
                name:'',
                payscale:0,
                deductionperday:0,
                email:null,
                password:'',
                confirm_password:'',
                isemployee:1,
                branchid:1,
                mobiles:null,
                addressess:null,
                profileimageid:0,
                username:null,
                fuel:0
            }),
            newemail:true,
            newusername:true,
            vsdigiid:'',
            isemp:false
		}
	},
    mounted(){
        
        if(this.$router.currentRoute.path=='/addemployee'){
              this.$store.commit("assignedititem",null)
              this.$store.commit("assignaddressess",null)
              this.$store.commit("assignselectedaddress",null)
            //   this.$store.commit("updateappendaddressess",null)
              this.reset();
        }else{
            this.initializedata();
        }
    },
    computed:{
        ...mapGetters([
		      'viewno','edititem','mobiles','addressess','selectedaddress','profileimage','uploadedimage'
		]),
        profileimageurl(){
            if(this.profileimage!=null){
                return this.profileimage.fullurl;
            }
            return '#'
        }
    },
    watch:{
        edititem:function(){ 
          this.initializedata();
        }
    },
	methods:{
        searchvsdigi(){
            if(this.vsdigiid!=''){
                let param={generatedid:this.vsdigiid}
                window.axios.post('api/user/getuserbygeneratedid',param)
                    .then(response=>this.processVsResponse(response.data))
                    .catch(error=>console.log(error));
            }
        },
        processVsResponse(data){
            if(data!=null){
                this.$store.commit("assignedititem",data)
                if(data.isemployee==1){
                    this.isemp=true
                    this.$notify({text:'Already an employee',type:'warn'})
                }else{
                    this.isemp=false
                }
                this.initializedata()
            }
        },
        keydownform(event){
			this.form.errors.clear(event.target.name);
			this.errormessage = '';
		},
        initializedata(){
            if (this.edititem!=null){
                this.form.id=this.edititem.id;
                this.form.name=this.edititem.name;
                this.form.payscale=this.edititem.payscale;
                this.form.deductionperday=this.edititem.deductionperday;
                this.form.branchid=this.edititem.branchid;
                this.form.fuel=this.edititem.fuel;
                // if(this.edititem.email!=null){
                //     this.newemail = false
                // }
                // if(this.edititem.username!=null){
                //     this.newusername = false
                // }
                this.form.email=this.edititem.email
                this.form.username=this.edititem.username
                this.$store.commit('assignmobiles',this.edititem.mobiles==null?[]:this.edititem.mobiles)
                this.$store.commit('assignaddressess',this.edititem.addressess==null?[]:this.edititem.addressess)
                if(this.edititem.addressess!=null && this.edititem.addressess.length>0){
                    this.$store.commit('assignselectedaddress',this.edititem.addressess[0])
                    
                }
                if(this.edititem.profileimage!=null){
                    this.form.profileimageid = this.edititem.profileimage.id
                    this.$store.commit('assignprofileimage',this.edititem.profileimage)
                }

            }else{
                this.form.name='',
                this.form.payscale=0,
                this.form.deductionperday=0,
                this.form.email=null,
                this.form.password='',
                this.form.confirm_password='',
                this.form.isemployee=1,
                this.form.branchid=1,
                this.form.mobiles=null,
                this.form.addressess=null,
                this.form.profileimageid=0,
                this.form.username=null
                this.form.fuel=0
                this.reset();
                this.form.id=0
            }
        },
        submit(){
            this.$store.commit('assignloadingstatus',1)
            this.form.mobiles = this.mobiles
            if(this.addressess!=null){
                this.form.addressess = this.addressess
            }
            if(this.profileimage!=null && this.form.profileimageid!=this.profileimage.id)
                this.form.profileimageid = this.profileimage.id
            this.form.submit(this,'post','api/user/updateprofile/others')
	    	.then(response=>this.processResponse(response))
	    	.catch(error=>this +(error));
            this.$notify({text:'Submitted',type:'warn'})
        },
        processResponse(data){
            
            this.$store.commit('updateappendemployees',data.user)
            this.$store.commit('assignviewno',1)
            this.$notify({
               text:'Employee Registered Successfully',
               type:'success',
               duration:'-1',
               speed:'100',
               closeOnClick:false
            })
            this.reset();
            this.$store.commit('assignloadingstatus',0)
        },
        uploaded(){
            this.$store.commit('assignprofileimage',this.uploadedimage)
        },
        cancel(){
            this.reset();
            this.$store.commit('assignviewno',1)
            this.$store.commit('assignloadingstatus',0)
        },
        
	}
}
</script>